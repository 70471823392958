import React from 'react';

const texts = {
    all: ['intro', 'tech', 'passions', 'contracts', 'projects', 'contact'],
    intro: <span>
        This is Bungalow64.  
        We know software engineering.  
        We build our own software, but we also work with people who need top-quality solutions.  
        We’re passionate about getting things right, and that includes the tools and languages we use, so we are always open to different technologies if that means the end result is better.
        To use this site, enter your command below.  Start off by calling 'help' to list all commands, or look at the menu on the right.</span>,
    help: <span>
        These are the commands you can use: intro | tech | passions | contracts | projects | contact | help</span>,
    tech: <span>
        We work mostly in the Microsoft stack, including .NET, C# and SQL Server.  
        We are also experts in JavaScript, APIs, Entity Framework, Selenium WebDriver, to name a few.  
        We never want to give the wrong impression, so if we're not familiar with a particular technology, we won't bluff our way around it, but tell it to you straight: whilst we may not be able to do something today, we're able to pick anything up pretty quickly.
        </span>,
    passions: <span>
        We love technology, but we also love seeing that technology used to solve real problems.  
        We love solutions that are fast, easily maintainable, and easy to understand.  
        We love automated testing, and we love seeing the assurance and quality that these tests bring.  
        We love making things better – whether that's optimising SQL to run super-fast, or hunting down a memory leak, or refactoring a bit of spaghetti to make the code nicer to work with.
        Mostly, though, we love working with people who are passionate about what they're doing - if you're excited about it, so are we.
        </span>,
    contracts: <span>
        If you need help with any form of software engineering (e.g., project work, or maintenance, or R&amp;D, whatever), then please just get in touch.  
        We can work on any size of project, and work to a range of budgets.  
        Just pop us an email at <a href="mailto:hello@bungalow64.co.uk">hello@bungalow64.co.uk</a>, and we can see how we might be able to help.
        </span>,
    projects: <span>
        We've worked on a range of different projects.  
        Including, but not limited to, full-stack applications, single-page apps, APIs, data warehouses, data cleansing tools, automated UI test suites, Power BI reports, Windows Services, Desktop apps.
        </span>,
    contact: <span>
        You can email us at <a href="mailto:hello@bungalow64.co.uk">hello@bungalow64.co.uk</a>.  Or you can find us on <a href="https://twitter.com/bungalow64tech" target="_blank" rel="noopener noreferrer">Twitter</a>, <a href="https://www.facebook.com/bungalow64technologies/" target="_blank" rel="noopener noreferrer">Facebook</a> and <a href="https://linkedin.com/company/bungalow64-technologies" target="_blank" rel="noopener noreferrer">LinkedIn</a>.
        </span>,
    unknown: <span>Sorry, that's not something I know about</span>
};

export default texts;