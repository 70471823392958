import { ENTER_COMMAND, ALL_COMMAND, CLEAR_COMMAND } from './actionTypes.js';

export const enterCommand = command => ({
    type: ENTER_COMMAND,
    payload: {
        command
    }
});

export const allCommand = () => ({
    type: ALL_COMMAND
});

export const clearCommand = () => ({
    type: CLEAR_COMMAND
});