import React from 'react'
import CurrentDate from '../Logic/CurrentDate.js';
import './Footer.scss';

const Footer = () => {
    return (
        <footer className="footer">&copy; {CurrentDate.now().getFullYear()} Bungalow64 Technologies Ltd.</footer>
    )
};

export default Footer;