import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import logo from '../logo.svg';
import Typist from 'react-typist';
import './App.scss';
import texts from '../Texts.js'
import Sidebar from './Sidebar.js'
import Form from './Form.js'
import Footer from './Footer.js'

const Output = (props) => {

  return (
    <div className={`output ${props.showCursor ? 'active-output' : ''}`}>
      <Typist avgTypingDelay={40}>
        {texts[props.command] || texts.unknown}
      </Typist>
    </div>
  )
};

function App(props) {

  const [showForm, setShowForm] = useState(false);
  const commands = props.commands;

  setTimeout(() => {
    setShowForm(true);
    setFocus();
  }, 100);

  const useFocus = () => {
    const element = useRef(null);
    const setFocus = () => { element.current && element.current.focus() };

    return [element, setFocus];
  };

  const [inputElement, setFocus] = useFocus();

  return (
    <>
    <div className="mainWrapper">
      <Sidebar />
      <div className='App'>
        <header className='App-header'>
          <img src={logo} className='App-logo' alt='logo' style={{ height: '80px' }} />
        </header>
        <div className='console'>
          {commands.map((command, index) =>
            <Output command={command} key={index} len={commands.length} showCursor={index === commands.length - 1} />
          )}
        </div>
        <Form
          display={showForm ? 'block' : 'none'}
          inputElement={inputElement} />
      </div>
    </div>
    <Footer/>
    </>
  );
}

const mapToProps = state => {
  return state;
};

export default connect(mapToProps)(App);