import { ENTER_COMMAND, ALL_COMMAND, CLEAR_COMMAND } from '../actionTypes.js';
import texts from '../../Texts.js';

const initialState = {
    commands: ['intro']
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ENTER_COMMAND:
            const { command } = action.payload;
            return {
                ...state,
                commands: [
                    ...state.commands, command
                ]
            };
        case ALL_COMMAND:
            return {
                ...state,
                commands: [
                    ...state.commands, ...texts.all
                ]
            };
        case CLEAR_COMMAND:
            return {
                ...state,
                commands: []
            };
        default:
            return state;
    }
};