import React, { useState } from 'react';
import { connect } from 'react-redux';
import { enterCommand, allCommand, clearCommand } from '../Redux/actions.js';
import './Form.scss';

const Form = (props) => {

    const [inputValue, setInputValue] = useState('');

    const enterCommand = e => {
        e.preventDefault();

        const command = props.inputElement.current.value.toLowerCase();

        switch (command) {
            case '':
                break;
            case 'clear':
                props.clearCommand();
                setInputValue('');
                break;
            case 'all':
                props.allCommand();
                setInputValue('');
                break;
            default:
                props.enterCommand(command);
                setInputValue('');
        }
    };

    return (
        <form className='form' onSubmit={e => enterCommand(e)} aria-label="form">
            <div className="column left">
                <label htmlFor="text-input">Command&gt; </label>
            </div>
            <div className="column center">
                <input id="text-input" type='text' ref={props.inputElement} value={inputValue} onChange={e => setInputValue(e.target.value)} />
            </div>
        </form>
    );
};

export default connect(null, { enterCommand, allCommand, clearCommand })(Form);