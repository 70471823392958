import React, { useState } from 'react';
import { connect } from 'react-redux';
import { enterCommand } from '../Redux/actions';
import './Sidebar.scss';

const MenuItem = (props) => {

    const navigate = e => {
        e.preventDefault();
        props.navigate(props.command);
    };

    return (
        <li>
            <a href={'#' + props.command} onClick={e => navigate(e)}>
                {props.command}
            </a>
        </li>
    );
}

const menuItems = [
    'intro',
    'help',
    'tech',
    'passions',
    'contracts',
    'projects',
    'contact'
];

const Sidebar = (props) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = e => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const navigate = (command) => {
        props.enterCommand(command);
        setIsOpen(false);
    };

    return (
        <div className={`menu ${isOpen ? 'opened' : ''}`} data-testid="menu">
            <a 
                className='title' 
                href='#menu' 
                onClick={e => toggleMenu(e)}
            >
                Commands
            </a>
            <ul className='nav'>
                {menuItems.map(p => <MenuItem key={p} command={p} navigate={navigate} />)}
            </ul>
        </div>
    );
};

export default connect(null, { enterCommand })(Sidebar);